import React from 'react'

import searchIcon from '@/assets/search.svg'

import { Stroker } from '../../SearchNearParking.styled'
import DateTimeControls from '../DateTimeControls/DateTimeControls'
import SearchBox from '../SearchBox'

import { OptionsWrapper, SearchButtonWrapper } from './Options.styled'

const DesktopSearch = ({
  market,
  placeholder,
  translatedSearchBtn
}: {
  market: string
  placeholder: string
  translatedSearchBtn: string
}) => {
  return (
    <OptionsWrapper data-test-id="options-wrapper">
      <SearchBox market={market} placeholder={placeholder} />
      <Stroker />
      <DateTimeControls />
      <SearchButtonWrapper type="submit" data-test-id="search-button-wrapper">
        <img src={searchIcon} alt="searchIcon" />
        <span>{translatedSearchBtn}</span>
      </SearchButtonWrapper>
    </OptionsWrapper>
  )
}

export default DesktopSearch
