import { useEffect, useState } from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'

import Title from '@/components/common/Title'
import HeroWrapperWithBackground from '@/components/common/HeroWrapperWithBackground'
import ScrollArrow from '@/components/common/ScrollArrow'
import {
  ContentWrapper,
  Container,
  GridWrapper
} from '@/components/common/HeroWrapperWithBackground.styled'

import Spinner from '../../common/SpinnerPage'
import Options from '../SearchNearParking/components/Options/Options'

import SearchHintsMainPage from './SearchHintsMainPage'

import { useHeroHomeLogic } from './useHeroHomeLogic'
import { Background, OptionsWrapper } from './HeroHome.styled'

const HeroHome = ({ control }: any) => {
  const { t } = useTranslation(['home', 'popups'])

  const { market, bg, heroTitle } = useHeroHomeLogic(t)

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])
  if (!mounted) {
    return (
      <Container heroHome={false}>
        <GridWrapper>
          <ContentWrapper>
            <Spinner />
          </ContentWrapper>
        </GridWrapper>
      </Container>
    )
  }

  const content = (
    <>
      <Title text={heroTitle} animated highlight={market === 'ae'} />
      <OptionsWrapper>
        <Options market={market} control={control} />
      </OptionsWrapper>
      <SearchHintsMainPage />
      <ScrollArrow anchor="discover-section" />
    </>
  )

  const images = (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          href={bg.desktop['1x_webp']}
          media="(min-width: 768px)"
          imageSrcSet={`${bg.desktop['1x_webp']}, ${bg.desktop['2x_webp']} 2x`}
          type="image/webp"
        />
        <link
          rel="preload"
          as="image"
          href={bg.mobile['1x_webp']}
          media="(max-width: 767px)"
          imageSrcSet={`${bg.mobile['1x_webp']}, ${bg.mobile['2x_webp']} 2x`}
          type="image/webp"
        />
      </Head>
      <Background>
        <source
          srcSet={`${bg.desktop['1x_webp']}, ${bg.desktop['2x_webp']} 2x`}
          media={`(min-width: 768px)`}
          type="image/webp"
        />
        <source
          srcSet={`${bg.mobile['1x_webp']}, ${bg.mobile['2x_webp']} 2x`}
          type="image/webp"
        />
        <img
          src={bg.mobile['1x_webp']}
          srcSet={`${bg.mobile['1x_webp']}, ${bg.mobile['2x_webp']} 2x`}
          alt={t('home:hero.imageAlt')}
        />
      </Background>
    </>
  )

  return (
    <HeroWrapperWithBackground content={content} images={images} heroHome />
  )
}

export default HeroHome
