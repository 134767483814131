import React from 'react'

import searchIcon from '@/assets/search.svg'

import { MobileSearchBoxWrapper, SearchButtonWrapper } from './Options.styled'
import MobileOptionsWrapper from './MobileOptionsWrapper'

interface MobileSearchProps {
  onOpenModal: () => void
  market: string
  translatedSearchBtn: string
  settedValue: string
  submitButtonRef: React.RefObject<HTMLButtonElement>
  settedStartDate: string
  settedEndDate: string
}

const MobileSearch: React.FC<MobileSearchProps> = ({
  onOpenModal,
  translatedSearchBtn,
  settedValue,
  submitButtonRef,
  settedStartDate,
  settedEndDate
}) => {
  return (
    <MobileSearchBoxWrapper data-test-id="mobile-search-box-wrapper">
      <MobileOptionsWrapper
        onOpenModal={onOpenModal}
        settedValue={settedValue}
        settedStartDate={settedStartDate}
        settedEndDate={settedEndDate}
      />
      <SearchButtonWrapper
        type="submit"
        data-test-id="search-button-wrapper"
        data-testid="search-box-button"
        ref={submitButtonRef}
      >
        <img src={searchIcon} alt="searchIcon" />
        <span>{translatedSearchBtn}</span>
      </SearchButtonWrapper>
    </MobileSearchBoxWrapper>
  )
}

export default MobileSearch
