import { useState, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { createPortal } from 'react-dom'

import DesktopSearch from './DesktopSearch'
import MobileSearch from './MobileSearch'
import SearchModal from './SearchModal'
import { DesktopWrapper, MobileWrapper } from './Options.styled'

const Options = ({ market, control }: { market: string; control: any }) => {
  const { t } = useTranslation('parkings')
  const [isModalOpen, setModalOpen] = useState(false)
  const [settedValue, setSettedValue] = useState('')
  const [settedStartDate, setSettedStartDate] = useState('')
  const [settedEndDate, setSettedEndDate] = useState('')
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const placeholder = t('searchNearParking.search.placeholder')
  const translatedSearchBtn = t('searchNearParking.search.button')

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
    <>
      <DesktopWrapper>
        <DesktopSearch
          market={market}
          placeholder={placeholder}
          translatedSearchBtn={translatedSearchBtn}
        />
      </DesktopWrapper>
      <MobileWrapper>
        <MobileSearch
          onOpenModal={openModal}
          market={market}
          translatedSearchBtn={translatedSearchBtn}
          settedValue={settedValue}
          settedStartDate={settedStartDate}
          settedEndDate={settedEndDate}
          submitButtonRef={submitButtonRef}
        />
      </MobileWrapper>
      {isModalOpen &&
        createPortal(
          <SearchModal
            settedValue={settedValue}
            setSettedValue={setSettedValue}
            setSettedStartDate={setSettedStartDate}
            setSettedEndDate={setSettedEndDate}
            onCloseModal={closeModal}
            market={market}
            settedStartDate={settedStartDate}
            settedEndDate={settedEndDate}
            control={control}
            submitButtonRef={submitButtonRef}
          />,
          document.getElementById('modal-search-mobile')!
        )}
    </>
  )
}

export default Options
