import React from 'react'

import { useTranslation } from 'next-i18next'

import {
  LocationInputWrapper,
  DateInputWrapper,
  DateInputItem,
  MobileOptionsWrapper as StyledMobileOptionsWrapper
} from './Options.styled'


interface MobileOptionsWrapperProps {
  onOpenModal: () => void
  settedValue: string
  settedStartDate: string
  settedEndDate: string
}

const MobileOptionsWrapper: React.FC<MobileOptionsWrapperProps> = ({
  onOpenModal,
  settedValue,
  settedStartDate,
  settedEndDate
}) => {
  const { t } = useTranslation('parkings')
  return (
    <StyledMobileOptionsWrapper
      data-test-id="options-wrapper"
      onClick={onOpenModal}
    >
      <LocationInputWrapper>
        {settedValue || t('searchNearParking.search.placeholder')}
      </LocationInputWrapper>
      <DateInputWrapper>
        <DateInputItem>
          {settedStartDate || t('searchNearParking.search.startDate')}
        </DateInputItem>
        <DateInputItem>
          {settedEndDate || t('searchNearParking.search.endDate')}
        </DateInputItem>
      </DateInputWrapper>
    </StyledMobileOptionsWrapper>
  )
}

export default MobileOptionsWrapper
