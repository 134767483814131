import React from 'react'

import { useTranslation } from 'next-i18next'

import Link from '@/components/common/Link'

import dotSVG from '@/assets/dot.svg'
import { useIsMobile } from '@/utils/useMobile'
import Spinner from '@/components/common/SpinnerPage'

import { useFetchCategories } from '@/service/apiHooks/useFetchCategories'
import { useMarket } from '@/utils/multi-markets/context'

import {
  HintsList,
  FlexContainer,
  HintsListItem,
  HintsListItemLink,
  Dot,
  Break,
  Wrapper
} from './SearchHinsMainPage.styled'

const SearchHintsMainPage = () => {
  const { market, locale } = useMarket()
  const { isMobile } = useIsMobile()
  const { t } = useTranslation('home')
  const { categories: categoriesParkFly } = useFetchCategories({
    params: { tag: 'PARK_AND_FLY', countryCode: market }
  })

  const { categories: categoriesCities } = useFetchCategories({
    params: { tag: 'LOCATIONS', countryCode: market }
  })

  const itemsPerRow = isMobile ? 4 : 6
  if (!categoriesParkFly || !categoriesCities) {
    return <Spinner />
  }
  const calculateRows = (idx: number, list: any) => idx < list.length - 1

  return (
    <Wrapper>
      <HintsList>
        {categoriesCities?.items?.map((el: any, idx: number) => (
          <React.Fragment key={idx}>
            <FlexContainer>
              <HintsListItem data-test-id={el.cityUrlSlug}>
                <Link
                  href={{
                    pathname: '/category/[category]',
                    query: {
                      category: el.categoryUrlSlugs[locale],
                      key: t(el.locizeIdentifier)
                    }
                  }}
                  passHref
                  prefetch={false}
                >
                  <HintsListItemLink>
                    {t(el.locizeIdentifier)}
                  </HintsListItemLink>
                </Link>
              </HintsListItem>
              {calculateRows(idx, categoriesCities?.items) &&
                (idx + 1) % itemsPerRow !== 0 && <Dot src={dotSVG} alt="dot" />}
            </FlexContainer>
            {(idx + 1) % itemsPerRow === 0 && <Break />}
          </React.Fragment>
        ))}
      </HintsList>
      <HintsList>
        {categoriesParkFly?.items?.map((el: any, idx: number) => (
          <div key={idx}>
            <FlexContainer>
              <HintsListItem data-test-id={el.cityUrlSlug}>
                <Link
                  href={{
                    pathname: '/category/[category]',
                    query: {
                      category: el.categoryUrlSlugs[locale],
                      key: t(el.locizeIdentifier)
                    }
                  }}
                  passHref
                  prefetch={false}
                >
                  <HintsListItemLink>
                    {t(el.locizeIdentifier)}
                  </HintsListItemLink>
                </Link>
              </HintsListItem>
              {calculateRows(idx, categoriesParkFly?.items) && (
                <Dot src={dotSVG} alt="dot" />
              )}
            </FlexContainer>
            {(idx + 1) % itemsPerRow === 0 && <Break />}
          </div>
        ))}
      </HintsList>
    </Wrapper>
  )
}

export default SearchHintsMainPage
