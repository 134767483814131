import { useQuery } from '@tanstack/react-query'

import { ApiPaths } from '@/utils/apiPaths'
import { fetchCategories } from '@/utils/parkingsService'

import { Categories } from '../parkings'

export const useFetchCategories = ({ params }: { params: Categories }) => {
  const { data: categories, isLoading } = useQuery({
    queryKey: [`${ApiPaths.API_V1_PARKINGS}`, params],
    queryFn: () => fetchCategories(params)
  })
  return {
    categories,
    isLoading
  }
}
